export const SouldOut = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.9271 5.34724C15.591 5.01113 15.0869 4.75905 14.4987 4.75905H13.8264V4.33891C13.8264 3.91877 13.5744 3.66669 13.2382 3.66669C12.9021 3.66669 12.566 3.91877 12.566 4.33891V4.75905H8.53268V4.33891C8.53268 3.91877 8.19657 3.66669 7.86046 3.66669C7.52435 3.66669 7.27227 3.91877 7.27227 4.33891V4.75905H6.68407C6.1799 4.75905 5.59171 4.9271 5.17157 5.34724C4.83546 5.76738 4.58337 6.27155 4.58337 6.85974V14.7584C4.58337 15.2625 4.75143 15.7667 5.17157 16.1868C5.50768 16.5229 6.01185 16.775 6.60004 16.775H14.5827C15.0869 16.775 15.591 16.5229 16.0112 16.1868C16.3473 15.8507 16.5994 15.3465 16.5994 14.7584V6.69169C16.5153 6.18752 16.3473 5.68335 15.9271 5.34724ZM15.2549 14.7584C15.2549 14.9264 15.1709 15.0945 15.0869 15.2625C14.9188 15.4306 14.7507 15.4306 14.5827 15.4306H6.51601C6.34796 15.4306 6.1799 15.3465 6.01185 15.2625C5.84379 15.0945 5.84379 14.9264 5.84379 14.7584V8.37224H14.2466C14.5827 8.37224 14.9188 8.12016 14.9188 7.70002C14.9188 7.27988 14.6667 7.0278 14.2466 7.0278H5.84379V6.85974C5.84379 6.60766 5.92782 6.4396 6.09588 6.27155C6.26393 6.10349 6.51601 6.01946 6.68407 6.01946H7.27227C7.77643 6.01946 8.25004 6.01946 8.53268 6.01946H12.65C12.9265 6.01946 13.4708 6.01946 13.8264 6.01946H14.4987C14.6667 6.01946 14.8348 6.10349 15.0028 6.18752C15.1709 6.35558 15.1709 6.52363 15.1709 6.69169V14.7584H15.2549Z"
            fill="white"
        />
        <path
            d="M9.62505 9.71669H7.77643C7.52435 9.71669 7.35629 9.88474 7.35629 10.1368V11.0611C7.35629 11.3132 7.52435 11.4813 7.77643 11.4813H9.62505C9.87713 11.4813 10.0452 11.3132 10.0452 11.0611V10.1368C10.0452 9.88474 9.87713 9.71669 9.62505 9.71669Z"
            fill="white"
        />
    </svg>
);
