export const IMAGES_ROOT = `https://${
    process.env.BUILD_ENV === 'prod' ? 'images' : 'staging-images'
}.tripshock.com/`;

export const IMAGES_ACTIVITY_ROOT = `${IMAGES_ROOT}activity/`;
export const IMAGES_CATEGORY_ROOT = `${IMAGES_ROOT}category/`;
export const IMAGES_DESTINATION_ROOT = `${IMAGES_ROOT}destination/`;
export const IMAGES_PHOTO_ROOT = `${IMAGES_ROOT}photo/`;
export const SYSTEM_FORMAT_DATE = 'yyyy-MM-dd';
export const SEARCH_FORMAT_DATE = 'MM/dd/yyyy';
export const SERVER_FORMAT_DATE = 'yyyy-MM-dd';
export const UNAVAILABLE_MONTH_FORMAT_DATE = 'yyyy-MM';
export const HELMET_LOADING_TITLE = 'TripShock! ...';
export const CRS_ITEM_FORMAT_DATE = 'yyyy-MM-dd HH:mm:ss XXX';
