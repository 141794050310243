import styled, { css } from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { radius } from '@/styles/Themes/Metrics';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import { Tomorrow } from '@/shared/images/icons/Tomorrow';
import { DropdownArrow } from '@/shared/images/icons/DropdownArrow';
import { Transition } from '@/styles/Themes/KeyFrames';
import { ArrowLeftCard } from '@/shared/images/icons/ArrowLeftCard';

export const CenterRightArrow = styled(ArrowLeftCard)`
    position: absolute;
    transform: rotate(180deg);
    height: 36px;
    width: 36px;
    right: 5px;
    top: 45%;

    ${Screen.ipad} {
        height: 36px;
        width: 36px;
    }
    ${Screen.desktop} {
        opacity: 0;
    }
`;

export const CenterLeftArrow = styled(ArrowLeftCard)`
    position: absolute;
    height: 36px;
    width: 36px;
    left: 5px;
    top: 45%;

    ${Screen.ipad} {
        height: 36px;
        width: 36px;
    }
    ${Screen.desktop} {
        opacity: 0;
    }
`;

export const GalleryWrapper = styled.div`
    position: relative;
    min-height: 100px;
    width: 100%;
    padding: 12px 12px 0;
`;

export const Container = styled.a<{ isLast?: boolean }>`
    position: relative;
    background-color: ${cssVars.bg_white_dark3};
    border-radius: ${radius.small};
    box-shadow: ${cssVars.shadow_card_default};
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 490px;
    overflow: hidden;
    border: 1px solid ${cssVars.bg_gray4_dark2};

    ${Screen.ipad} {
        height: 550px;
        overflow-y: hidden;
    }

    ${Screen.desktop} {
        height: 560px;
        border: none;
        &:hover ${CenterLeftArrow}, &:hover ${CenterRightArrow} {
            opacity: 1;
            transition: 0.55s;
        }
    }

    &:hover {
        box-shadow: ${cssVars.shadow_card_hover};
        ${Transition.normal};
    }

    ${({ isLast }) =>
        isLast &&
        css`
            &:nth-last-of-type(1) {
                height: 200px;
                filter: blur(4px);
                pointer-events: none;
                ${ContentContainer} {
                    display: none;
                }
            }

            ${Screen.ipad} {
                &:nth-last-of-type(1) {
                    height: 240px;
                }
            }

            ${Screen.desktop} {
                &:nth-last-of-type(1):nth-child(odd) {
                    display: none;
                }
                &:nth-last-of-type(-n + 3):nth-child(odd),
                &:nth-last-of-type(-n + 2):nth-child(even) {
                    height: 240px;
                    filter: blur(4px);
                    pointer-events: none;

                    ${ContentContainer} {
                        display: none;
                    }
                }
            }
        `}
`;

export const Disabled = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${cssVars.bg_gray4_dark2};
    opacity: 0.4;
    z-index: 1;
`;

export const ContentContainer = styled.div<{ isActive: boolean }>`
    position: relative;
    height: 215px;
    padding: 22px 20px 0;
    display: flex;
    flex-direction: column;
    z-index: 0;

    ${Screen.ipad} {
        height: 206px;
        padding: 25px 24px 0;
    }

    ${Screen.desktop} {
        height: 230px;
    }

    ${({ isActive }) =>
        isActive &&
        css`
            height: auto !important;

            @keyframes up {
                from {
                    max-height: 300px;
                }

                to {
                    max-height: 600px;
                }
            }

            animation-duration: 1s;
            animation-name: up;
        `}
`;

export const Title = styled.h4`
    flex: none;
    color: ${cssVars.fg_gray1_white};
    ${Text.Mobile.heading4.bold}
    margin: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    ${Screen.ipad} {
        margin: 5px 0;
        ${Text.Ipad.heading4}
    }
`;

export const DurationWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.large.regular}

    margin: 0 0 10px;
    ${Screen.ipad} {
        margin: 0 0 14px;
    }

    ${Screen.desktop} {
        ${Text.Full.medium.regular}
    }
`;

export const ClockIcon = styled(Tomorrow)``;

export const RatingText = styled.span`
    margin: 0 auto 0 6px;
`;

export const Description = styled.div<{ readMore?: boolean }>`
    word-break: break-word;
    overflow-y: ${({ readMore }) => (readMore ? 'auto' : 'hidden')};
    color: ${cssVars.fg_gray2_white};
    ${Text.Full.medium.regular}
    margin: 0 0 auto;
    text-overflow: ellipsis;

    ${({ readMore }) =>
        readMore &&
        css`
            overflow-y: scroll;
            max-height: 210px;
            width: calc(100% + 10px);
            padding-right: 10px;
        `}
    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: ${cssVars.bg_ash_blueDark};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        height: 30px;
        background: ${Palette.gray.gray3};
        border-radius: 10px;
    }
`;

export const SpecialStatus = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${cssVars.fg_gray2_white};
    background-color: ${cssVars.bg_gray4_dark1};
    ${Text.Full.medium.medium}
    border-radius: 2px;
    margin-bottom: 4px;
    text-align: center;
    padding: 2px;
`;

export const ReadMore = styled.span<{ isActive: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 36px;
    ${Text.Full.medium.medium}
    color: ${Palette.green.normal};
    ${Transition.normal};
    margin-bottom: 13px;

    ${({ isActive }) =>
        !isActive &&
        css`
            box-shadow: 0px -8px 16px 8px ${cssVars.bg_white_dark3};
        `}
`;

export const Arrow = styled(DropdownArrow)<{ isActive: boolean }>`
    margin-left: 6px;
    fill: ${Palette.green.normal};
    ${({ isActive }) => isActive && 'transform: rotate(-180deg);'}
    ${Transition.normal};
`;

export const Duration = styled.div`
    display: flex;
    align-items: center;
`;

export const BeFirst = styled.div`
    color: ${Palette.sand.darkSand};
`;
