import { memo, ReactElement, useMemo } from 'react';
import { format, addDays, parse, isBefore } from 'date-fns';
import { SERVER_FORMAT_DATE } from 'utils/constants';
import * as S from './AvailabilityThumb.styles';
import { IDaysAvailability } from '@/common/service/api/Availability/Availability.domain';
const dateToNumber = (v: string): number => Number(v.split('-').reverse().join());
const dateSortFunc = (a: string, b: string): number => dateToNumber(a) - dateToNumber(b);

type AvailabilityThumbProps = {
    daysAvailability?: IDaysAvailability;
    price?: number | null;
};

const AvailabilityThumb = ({ daysAvailability }: AvailabilityThumbProps): ReactElement => {
    const args = useMemo(() => {
        if (
            !daysAvailability ||
            typeof daysAvailability !== 'object' ||
            daysAvailability.length < 1
        ) {
            return null;
        }

        const now = new Date();
        const today = format(now, SERVER_FORMAT_DATE);
        const isAvailToday = !!daysAvailability[today];

        if (isAvailToday) {
            return {
                isAvailToday,
                isAvailTomorrow: false,
                isAvailNearest: false,
                nearestDate: null,
                nearestFormattedDate: '',
                isSoldOut: false,
            };
        }

        const isAvailTomorrow = !!daysAvailability[format(addDays(now, 1), SERVER_FORMAT_DATE)];

        if (isAvailTomorrow) {
            return {
                isAvailToday: false,
                isAvailTomorrow,
                isAvailNearest: false,
                nearestDate: null,
                nearestFormattedDate: '',
                isSoldOut: false,
            };
        }

        const sortedDates = Object.keys(daysAvailability).sort(dateSortFunc);
        const date = sortedDates.find((serverDate) => !!daysAvailability[serverDate]);

        const isAvailNearest = !!date;
        const nearestDate = isAvailNearest ? parse(date, SERVER_FORMAT_DATE, new Date()) : null;
        const thisWeek = isBefore(
            parse(date as string, SERVER_FORMAT_DATE, new Date()),
            addDays(now, 7)
        );

        const nearestFormattedDate =
            isAvailNearest && thisWeek
                ? format(nearestDate as Date, 'iiii')
                : isAvailNearest
                ? format(nearestDate as Date, 'MMMM do')
                : '';

        const isSoldOut = !isAvailNearest && !thisWeek;
        return {
            isAvailToday: false,
            isAvailTomorrow: false,
            isAvailNearest,
            nearestDate,
            nearestFormattedDate,
            isSoldOut,
        };
    }, [daysAvailability]);

    if (!args) {
        return <></>;
    }

    const { isAvailToday, isAvailTomorrow, isAvailNearest, nearestFormattedDate, isSoldOut } = args;

    if (!isAvailToday && !isAvailTomorrow && !isAvailNearest && !isSoldOut) {
        return <></>;
    }

    return (
        <>
            {/* {!!price && !isSoldOut && <S.NewToTripshock>Save now!</S.NewToTripshock>} */}
            <S.AvailabilityThumb
                data-test-id="availability-thumb"
                today={isAvailToday || isAvailTomorrow}
                soldout={isSoldOut}
            >
                {isAvailToday && (
                    <>
                        <S.TodayIcon /> Experience today
                    </>
                )}
                {isAvailTomorrow && (
                    <>
                        <S.TomorrowIcon /> Experience tomorrow
                    </>
                )}
                {isAvailNearest && (
                    <>
                        <S.TomorrowIcon />
                        {` Next Available: ${nearestFormattedDate}`}
                    </>
                )}
                {isSoldOut && (
                    <>
                        <S.SouldOutIcon />
                        <b>Sold out&nbsp;</b> - try adjusting your dates
                    </>
                )}
            </S.AvailabilityThumb>
        </>
    );
};

export default memo(AvailabilityThumb);
