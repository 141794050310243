export const Today = () => (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5241 0.442385C11.7297 0.518669 11.8509 0.731362 11.8119 0.947099L10.513 8.12066H14.3672C14.5297 8.12066 14.6797 8.20782 14.7602 8.34899C14.8407 8.49016 14.8393 8.66365 14.7565 8.80349L8.6068 19.1921C8.49513 19.3807 8.26427 19.4621 8.05895 19.3853C7.85364 19.3085 7.73296 19.0955 7.77259 18.8799L9.07343 11.8013H5.20213C5.03987 11.8013 4.89003 11.7144 4.80946 11.5736C4.72889 11.4327 4.72993 11.2595 4.81219 11.1196L10.9768 0.637176C11.0879 0.448189 11.3186 0.366102 11.5241 0.442385ZM5.99298 10.8966H9.61652C9.75081 10.8966 9.87816 10.9562 9.96411 11.0594C10.0501 11.1626 10.0857 11.2986 10.0614 11.4307L9.12122 16.547L13.5737 9.02542H9.97132C9.83724 9.02542 9.71006 8.96594 9.62411 8.86303C9.53816 8.76012 9.50229 8.62438 9.52618 8.49244L10.469 3.2854L5.99298 10.8966Z"
            fill="white"
        />
    </svg>
);
